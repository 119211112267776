import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { InternalUser } from './_models/internalUser';

import { NotificationService } from 'app/_services/notification.service';
import { SupportUsuarioInternoService } from './usuario-interno.service';
import { RolesIdsEnum } from 'app/_models/roles';
import { Repairer } from './_models/repairer';

declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-usuario-interno', templateUrl: './usuario-interno.component.html', styleUrls: ['./usuario-interno.component.scss'] })
export class SupportUsuarioInternoComponent implements OnInit, OnDestroy {
    _searchString: string;
    filteredUsers: InternalUser[];
    internalUsers: InternalUser[];
    isEditProfile: boolean;
    isList: boolean;
    repairers: Repairer[];
    selectedUser: InternalUser;

    constructor(
        private notificationService: NotificationService,
        private usuarioInternoService: SupportUsuarioInternoService,
        private globals: Globals,
        private route: ActivatedRoute,
        private router: Router,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit(): void {
        let urlSegments = this.route.snapshot.url;
        let param_id = this.route.snapshot.params.id;
        let param_associations = urlSegments.some(segment => segment.path === 'associations');
        let param_new_user = urlSegments.some(segment => segment.path === 'new');
        this.repairers = [];
        this.isList = true;
        this.filteredUsers = [];
        this.selectedUser = {} as InternalUser;
        this.internalUsers = [];
        this.searchTerm = '';

        this.load(0, param_new_user, param_associations, param_id);
    }
    
    ngOnDestroy(): void {
        this.usuarioInternoService.resetSortDataValue();
    }

    action_backToList() {
        this.showUserList();
        history.pushState({}, '', '/suporte/manutencao/usuario/interno');
    }

    async action_editProfile(param) {
        this.selectInternalUser(param.userId);

        let isCentralSupport = this.selectedUser.role == RolesIdsEnum.CentralSuporte;

        if (!param.isAssociations && isCentralSupport) {
            let option = await this.notificationService.showConfirmationDialog('support.manutencao.usuario.interno.lista.mensagens.confirmacao-atualizacao-perfil');

            if (!option)
                return;
        }        

        if (!isEmpty(this.selectedUser)) {
            if (isCentralSupport) {
                this.updateRepairersForCentralSuporte(this.selectedUser);
                this.router.navigate(['/suporte/manutencao/usuario/interno']);
                return;
            }

            this.isList = false;
            this.isEditProfile = true;
            history.pushState({}, '', '/suporte/manutencao/usuario/interno/associations/' + param.userId);
        } else {
            this.router.navigate(['/suporte/manutencao/usuario/interno']);
        }
    }

    async action_editUser(param) {
        this.selectInternalUser(param.userId);

        if (!isEmpty(this.selectedUser)) {
            this.isList = false;
            this.isEditProfile = false;
            history.pushState({}, '', '/suporte/manutencao/usuario/interno/' + param.userId);
        }
        else {
            this.router.navigate(['/suporte/manutencao/usuario/interno']);
        }
    }

    async action_removeProfile(param) {
        let option = await this.notificationService.showConfirmationDialog('support.manutencao.usuario.interno.lista.mensagens.confirmacao-remover-perfil.title', 'support.manutencao.usuario.interno.lista.mensagens.confirmacao-remover-perfil.message');

        if (!option)
            return;

        this.removeInternalUser(param.userId, 0);
    }

    action_new() {
        this.selectedUser = {} as InternalUser;
        this.isList = false;
        this.isEditProfile = false;
        history.pushState({}, '', '/suporte/manutencao/usuario/interno/new');
    }

    action_updateUser(user: InternalUser) {
        let index = this.internalUsers.findIndex(u => u.userId == user.userId);

        if (index === -1) {
            this.internalUsers.push(user);
        } else {
            this.internalUsers[index] = user;
        }

        this.updateFilteredUsers();
        this.showUserList();

        history.pushState({}, '', '/suporte/manutencao/usuario/interno');
    }

    closeUpdateCreation() {
        this.action_backToList();
    }

    filterUsers() {
        if (isEmpty(this.internalUsers))
            return this.internalUsers;

        let term: string = this._searchString.toLowerCase();
        let result = this.internalUsers.filter(user => user.name.toLowerCase().indexOf(term) !== -1 || user.roleDescription.toLowerCase().indexOf(term) !== -1);
        return result;
    }

    load(nAttempts: number, isNewUser:boolean, isAssociations: boolean, userId?: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.usuarioInternoService.getAllInternalUsers().subscribe({
            next: (response: ApiResponseModel<InternalUser[]>) => {
                if (response && response.success) {
                    this.internalUsers = response.result;
                    this.updateFilteredUsers();

                    if (isNewUser) {
                        this.action_new();
                    } else if (userId > 0) {
                        isAssociations ? this.action_editProfile({ userId, isAssociations }) : this.action_editUser({ userId });
                    }
                }
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.lista.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.load(++nAttempts, isNewUser, isAssociations, userId);
            }
        });

        this.usuarioInternoService.getAllRepairers().subscribe({
            next: (response: ApiResponseModel<Repairer[]>) => {
                if (response && response.success) {
                    this.repairers = response.result;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.lista.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.load(++nAttempts, isNewUser, isAssociations, userId);
            }
        });
    }

    removeInternalUser(userId: number, nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.usuarioInternoService.delete(userId).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success) {
                    let index = this.internalUsers.findIndex(u => u.userId == userId);
                    this.internalUsers.splice(index, 1);
                    this.updateFilteredUsers();
                    this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.sucesso.remover.corpo'));
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.lista.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.removeInternalUser(userId, ++nAttempts);
            }
        });
    }

    selectInternalUser(userId: number) {
        this.selectedUser = this.internalUsers.find(u => u.userId == userId);

        if (isEmpty(this.selectedUser)) {
            this.selectedUser = {} as InternalUser;
        }
    }

    showUserList() {
        this.selectedUser = null;
        this.isList = true;
        this.isEditProfile = false;
    }

    updateFilteredUsers() {
        const sortData = this.usuarioInternoService.getSortDataValue();

        let filteredUsers = this.filterUsers().sort(sort_by([{ name: sortData.sortedBy, reverse: sortData.isReverse }]));
        this.filteredUsers = filteredUsers;
    }

    updateRepairersForCentralSuporte(user: InternalUser) {
        this.ngxLoader.startLoader('loader-principal');

        this.usuarioInternoService.update(user).subscribe({
            next: (response: ApiResponseModel<InternalUser>) => {
                this.ngxLoader.stopLoader('loader-principal');
                let index = this.internalUsers.findIndex(u => u.userId == response.result.userId);
                this.internalUsers[index] = response.result;
                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.usuario.interno.lista.mensagens.associacoes-atualizada-sucesso'));
            },
            error: () => {
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.lista.mensagens.erro.carregamento.corpo'));
                return;
            }
        });
    }

    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.updateFilteredUsers();
    }
}
