import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";

import { InternalUser } from "./_models/internalUser";
import { SuportGridviewSort } from "../_models/supportGridview";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SupportUsuarioInternoService extends BaseService {
    private _apiSupportUrl: string = `${environment.apiSupport}`;
    private _domain: string = '/internal-users';

    private _sortDataSubject = new BehaviorSubject<SuportGridviewSort>({ enabled: true, sortedBy: 'name', isReverse: false });
    public sortDataValue$ = this._sortDataSubject.asObservable();

    constructor(private config: SettingsService) {
        super();
    }

    public create(user: InternalUser): Observable<any> {
        return this.config.executePost({ baseUrl: this._apiSupportUrl, url: this._domain, body: user });
    }

    public delete(userId: number): Observable<any> {
        return this.config.executeDelete({ baseUrl: this._apiSupportUrl, url: `${this._domain}/${userId}` });
    }

    public getAllInternalUsers(): Observable<any> {
        return this.config.executeGet({ baseUrl: this._apiSupportUrl, url: this._domain });
    }

    public getAllRepairers(): Observable<any> {
        return this.config.executeGet({ baseUrl: this._apiSupportUrl, url: `${this._domain}/repairers` });
    }

    public getSortDataValue(): SuportGridviewSort {
        return this._sortDataSubject.value;
    }

    public resetSortDataValue(): void {
        this._sortDataSubject.next({ enabled: true, sortedBy: 'name', isReverse: false });
    }

    public setSortDataValue(value: SuportGridviewSort): void {
        this._sortDataSubject.next(value);
    }

    public update(user: InternalUser): Observable<any> {
        return this.config.executePut({ baseUrl: this._apiSupportUrl, url: this._domain, body: user });
    }

    public updateRepairers(userId: number, repairers: number[]): Observable<any> {
        return this.config.executePut({ baseUrl: this._apiSupportUrl, url: `${this._domain}/${userId}/repairers`, body: { buyerIds: repairers } });
    }
}
