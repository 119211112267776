<div class="usuario-interno-lista">
    <div class="card col-md-8 offset-md-2">
        <app-support-gridview #gridViewComponent [config]="config" [templates]="templatesArray" (onSort)="onSort($event)"></app-support-gridview>

        <ng-template #buttonsTemplate let-user>
            <i class="fa fa-1x fa-edit cursor-pointer mt-1" tooltip="{{'support.cotacoes.vendedores.atualizar.tooltip-perfil' | translate}}" (click)="onEdit(user)"></i>
            @if (user.role === roles.Central || user.role === roles.CentralSuporte) {
                <i [ngClass]="{'fa-refresh': user.role === roles.CentralSuporte, 'fa-users': user.role === roles.Central }" class="fa fa-1x cursor-pointer" tooltip="{{'support.cotacoes.vendedores.atualizar.tooltip-associacoes' | translate}}" (click)="onEditUserProfile(user)"></i>
            } @else {
                <div style="width: 20px;"></div>
            }
            <i class="fa fa-1x fa-trash cursor-pointer" tooltip="{{'support.cotacoes.vendedores.atualizar.tooltip-excluir' | translate}}" (click)="onRemoveUserProfile(user)"></i>
        </ng-template>
    </div>
</div>
